import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { openModal } from '../../../app/modals/redux/modalActions';
import {
  statusWhatsapp,
  qrcodeWhatsapp,
  unlinkWhatsapp,
  restartWhatsapp,
} from './redux/reduxApi';
import ReactTooltip from 'react-tooltip';

const mapState = (state, ownProps) => {
  let whatsappStatus;
  let whatsappInfo;
  if (state && state.whatsapps) {
    whatsappStatus = state && state.whatsapps && state.whatsapps.status;
    whatsappInfo = state && state.whatsapps && state.whatsapps.info;
  }
  return {
    auth: state.auth,
    loading: state.async.loading,
    whatsappStatus: whatsappStatus,
    whatsappInfo: whatsappInfo,
  };
};

const actions = {
  openModal,
  statusWhatsapp,
  qrcodeWhatsapp,
  unlinkWhatsapp,
  restartWhatsapp,
};

function SettingForm(props) {
  const {
    auth,
    loading,
    history,
    whatsappStatus,
    whatsappInfo,
    statusWhatsapp,
    qrcodeWhatsapp,
    restartWhatsapp,
  } = props;
  const [state, setState] = useState({
    status: 'initialize',
    info: null,
    qrCodeUrl: null,
  });

  const getQrcodeurl = async () => {
    let qrCodeUrl = await qrcodeWhatsapp(auth, history);
    setState({
      ...state,
      qrCodeUrl,
      status: whatsappStatus,
    });
  };

  useEffect(() => {
    statusWhatsapp(auth, history);
    setState({
      ...state,
      status: whatsappStatus,
      info: whatsappInfo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let getStatus = setInterval(() => {
      statusWhatsapp(auth, history);
      setState({
        ...state,
        status: whatsappStatus,
        info: whatsappInfo,
      });
    }, 2500);

    //Clean up
    return () => {
      clearInterval(getStatus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (state && state.status === 'QRCode') {
      let getQrcode = setInterval(() => {
        getQrcodeurl();
      }, 1000);

      //Clean up
      return () => {
        clearInterval(getQrcode);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleRestart = () => {
    restartWhatsapp(auth, history);
  };

  return (
    <>
      <div className='level'>
        <div className='level-left'></div>
        <div className='level-right'>
          <div className='level-item'>
            <div data-for='main' data-tip='Restart Server Whatsapp'>
              <button
                            disabled={loading}
                onClick={() => handleRestart()}
                className={
                  loading
                    ? 'button is-small is-danger is-rounded is-outlined is-loading'
                    : 'button is-small is-danger is-rounded is-outlined'
                }
                style={{ marginRight: 10 }}
              >
                <i className='is-size-6 mdi mdi-power icon' />
              </button>
              <ReactTooltip
                id='main'
                place='bottom'
                type='dark'
                effect='solid'
                multiline={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='columns is-justify-content-center mt-5 mb-5'>
        <div className='column is-6'>
          <div style={{ height: '40%' }}>
            {state && state.qrCodeUrl && (
              <div className='card-image'>
                <figure
                  className='image is-square'
                  style={{ height: 200, width: 'auto' }}
                >
                  <img src={state.qrCodeUrl} alt='Placeholder' />
                </figure>
              </div>
            )}
            {state && !state.qrCodeUrl && (
              <div className='card-image has-text-centered'>
                <i className='mdi mdi-whatsapp has-text-primary is-size-1 icon' />
              </div>
            )}
            <div className='card-content'>
              {state && !state.qrCodeUrl && (
                <div className='media has-text-centered'>
                  <div className='media-content'>
                    <p className='title is-4'>
                      {(state && state.info && state.info.pushname) ||
                        'Name: no data'}
                    </p>
                    <p className='subtitle is-6'>
                      {state &&
                      state.info &&
                      state.info.me &&
                      state.info.me.user
                        ? state.info.me.user.replace('62', '0')
                        : 'Phone: no data'}
                    </p>
                  </div>
                </div>
              )}
              <div className='content has-text-centered mt-1'>
                <span className='tag is-info'>
                  {state.status
                    ? state.status !== 'QRCode'
                      ? state.status
                      : 'Scan QRCode'
                    : 'No Connection'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapState, actions)(SettingForm);
