import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import {
  emailsGet,
  emailGet,
  emailAdd,
  emailSend,
  emailUploadFile,
  emailDelete,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';
import { toastr } from 'react-redux-toastr';

export const getEmails = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getEmails' });
    const formData = new FormData();
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'email/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
      };
      dispatch(emailsGet({ total, items, search }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addEmail = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addEmail' });
    let logs = logsFunc('add', auth.userId, auth.username);
    const formData = new FormData();
    formData.append('id', id);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'email/add/' + id,
        formData,
        dispatch
      );
      const email = res?.email;
      dispatch(asyncActionFinish());
      if (email) {
        dispatch(emailAdd(email));
        return email;
      }
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadFileEmail = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadFileEmail' });
    dispatch(emailUploadFile('start'));
    let logs = logsFunc('edit', auth.userid, auth.username);
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('recipients', values.recipients);
    formData.append(
      'attachments',
      values.attachments ? JSON.stringify(values.attachments) : ''
    );
    formData.append('fileId', values.fileId);
    formData.append('file', values.file);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'uploadAttach',
        auth,
        history,
        'email/upload-file/' + values.id,
        formData,
        dispatch
      );
      if (!res) {
        dispatch(asyncActionFinish());
        return;
      }
      dispatch(emailGet(res.email));
      dispatch(emailUploadFile('finish'));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const sendEmail = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'sendEmail' });
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('recipients', values.recipients);
    formData.append(
      'attachments',
      values.attachments ? JSON.stringify(values.attachments) : ''
    );
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'email/send/' + values.id,
        formData,
        dispatch
      );
      const email = res.email;
      dispatch(emailSend(email));
      toastr.success('Success', 'Email diproses...');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteEmail = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteEmail' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'email/delete/' + id,
        formData,
        dispatch
      );
      const email = res.email;
      dispatch(emailDelete(email));
      history.push({ pathname: '/test', state: { activeTab: 'email' } });
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
