import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import asyncReducer from '../common/reduxs/async/asyncReducer';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import modalReducer from '../modals/redux/modalReducer';
import sessionStateReducer from '../common/reduxs/session/reduxReducer';
import authReducer from '../login/redux/authReducer';
import { reducer as formReducer } from 'redux-form';
import menusReducer from '../common/reduxs/menu/menusReducer';
import dashboardReducer from '../../menus/dashboard/redux/reduxReducer';
import userReducer from '../../menus/users/redux/reduxReducer';
import roleReducer from '../../menus/roles/redux/reduxReducer';
import searchFormReducer from '../common/reduxs/search/reduxReducer';
import rppReducer from '../../menus/_features/rpp/redux/reduxReducer';
import materiReducer from '../../menus/_features/materi/redux/reduxReducer';
import tugasReducer from '../../menus/_features/tugas/redux/reduxReducer';
import latihanReducer from '../../menus/_features/latihan/redux/reduxReducer';
import tesReducer from '../../menus/_features/tes/redux/reduxReducer';
import pesanpribadiReducer from '../../menus/_features/pesanpribadi/redux/reduxReducer';
import slideshowReducer from '../../menus/_features/slideshow/redux/reduxReducer';
import announcementReducer from '../../menus/_features/announcement/redux/reduxReducer';
import whatsappReducer from '../../menus/_features/whatsapp/redux/reduxReducer';
import calendarReducer from '../../menus/_features/calendar/redux/reduxReducer';
import portaluserReducer from '../../menus/_features/portaluser/redux/reduxReducer';
import jenjangReducer from '../../menus/_features/jenjang/redux/reduxReducer';
import tingkatReducer from '../../menus/_features/tingkat/redux/reduxReducer';
import kelasReducer from '../../menus/_features/kelas/redux/reduxReducer';
import pelajaranReducer from '../../menus/_features/pelajaran/redux/reduxReducer';
import yearReducer from '../../menus/_features/year/redux/reduxReducer';
import semReducer from '../../menus/_features/sem/redux/reduxReducer';
import emailReducer from '../../menus/tests/email/redux/reduxReducer';

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const appReducer = combineReducers({
  auth: authReducer,
  form: formReducer,
  menus: menusReducer,
  toastr: toastrReducer,
  async: asyncReducer,
  modals: modalReducer,
  sessionState: sessionStateReducer,
  dashboard: dashboardReducer,
  users: userReducer,
  roles: roleReducer,
  searchForm: searchFormReducer,
  rpps: rppReducer,
  materis: materiReducer,
  tugass: tugasReducer,
  latihans: latihanReducer,
  tess: tesReducer,
  pesanpribadis: pesanpribadiReducer,
  slideshows: slideshowReducer,
  announcements: announcementReducer,
  whatsapps: whatsappReducer,
  calendars: calendarReducer,
  portalusers: portaluserReducer,
  jenjangs: jenjangReducer,
  tingkats: tingkatReducer,
  kelass: kelasReducer,
  pelajarans: pelajaranReducer,
  years: yearReducer,
  sems: semReducer,
  emails: emailReducer,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    state = initialState;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
