import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import {
  pesanpribadisGet,
  pesanpribadiGet,
  pesanpribadiPortalusersGet,
  pesanpribadiEdit,
  pesanpribadiSend,
  pesanpribadiReport,
  pesanpribadiUploadFile,
  pesanpribadiDelete,
} from './reduxAction';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';
import { toastr } from 'react-redux-toastr';

export const getPesanpribadis = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getPesanpribadis' });
    const formData = new FormData();
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'pesan-pribadi/',
        formData,
        dispatch
      );
      const items = res?.items;
      const total = res?.total;
      dispatch(pesanpribadisGet({ total, items }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getPesanpribadiPortalusers = (auth, history) => {
  return async (dispatch) => {
    dispatch({
      type: ASYNC_ACTION_START,
      payload: 'getPesanpribadiPortalusers',
    });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'pesan-pribadi/portalusers',
        formData,
        dispatch
      );
      const portalusers = res.portalusers;
      dispatch(pesanpribadiPortalusersGet(portalusers));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getPesanpribadi = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getPesanpribadi' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'pesan-pribadi/view/' + id,
        formData,
        dispatch
      );
      const pesanpribadi = res.pesanpribadi;
      dispatch(pesanpribadiGet(pesanpribadi));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addPesanpribadi = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addPesanpribadi' });
    let logs = logsFunc('add', auth.userid, auth.username);
    const formData = new FormData();
    formData.append('id', values.messageId);
    formData.append('recipients', JSON.stringify(values.recipients));
    formData.append('title', values.title.trim());
    formData.append('messages', values.messages);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'pesan-pribadi/add/' + values.messageId,
        formData,
        dispatch
      );
      const pesanpribadi = res.pesanpribadi;
      dispatch(pesanpribadiGet(pesanpribadi));
      history.push({
        pathname: '/portal/pesanpribadi/' + values.messageId,
        state: {},
      });
      toastr.success('Success', 'Pesan berhasil disimpan.');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadFilePesanpribadi = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadFilePesanpribadi' });
    let file = values.file;
    dispatch(
      pesanpribadiUploadFile({
        messageId: values.messageId,
        studentMessageId: values.studentMessageId,
        filename: file.name,
        filesize: file.size,
        filetype: file.type,
        status: 'start',
      })
    );
    let logs = logsFunc('add', auth.userid, auth.username);
    const formData = new FormData();
    formData.append('messageId', values.messageId);
    formData.append('studentMessageId', values.studentMessageId);
    formData.append('filename', file.name);
    formData.append('filesize', file.size);
    formData.append('filetype', file.type);
    formData.append('file', values.file);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'uploadAttach',
        auth,
        history,
        'pesan-pribadi/upload-file/' + values.messageId,
        formData,
        dispatch
      );
      if (!res) {
        dispatch(asyncActionFinish());
        return;
      }
      dispatch(pesanpribadiGet(res.message));
      dispatch(
        pesanpribadiUploadFile({
          messageId: values.messageId,
          studentMessageId: values.studentMessageId,
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
          status: 'finish',
        })
      );
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const sendPesanpribadi = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'sendPesanpribadi' });
    let logs = logsFunc('send', auth.userid, auth.username);
    const formData = new FormData();
    formData.append('id', values.messageId);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'pesan-pribadi/send/' + values.messageId,
        formData,
        dispatch
      );
      const pesanpribadi = res.pesanpribadi;
      dispatch(pesanpribadiSend(pesanpribadi));
      toastr.success('Success', 'Pesan dikirim...');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const reportPesanpribadi = (auth, history, messageId) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'reportPesanpribadi' });
    const formData = new FormData();
    formData.append('id', messageId);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'pesan-pribadi/report/' + messageId,
        formData,
        dispatch
      );
      const reports = res.reports;
      dispatch(pesanpribadiReport(reports));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const importPesanpribadi = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'importPesanpribadi' });
    try {
      let logs = logsFunc('import', auth.userid, auth.username, null);
      const id = values.importId;
      const formData = new FormData();
      formData.append('id', id);
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('messages', JSON.stringify(values.messages));
      formData.append('recipients', JSON.stringify(values.recipients));
      formData.append('logs', logs);
      const res = await fetchFunc(
        'import',
        auth,
        history,
        'pesan-pribadi/import/' + id,
        formData,
        dispatch
      );
      if (res && res.status === 'success') {
        history.push('/portal/pesanpribadi/' + id);
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteFilePesanpribadi = (auth, history, data, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteFilePesanpribadi' });
    let logs = logsFunc('edit', auth.userid, auth.username, data.logs);
    const formData = new FormData();
    formData.append('studentMessageId', data.studentMessageId);
    formData.append('filelink', file.filelink);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'deleteFile',
        auth,
        history,
        'pesan-pribadi/delete-file/' + data.messageId,
        formData,
        dispatch
      );
      const pesanpribadi = res.message;
      dispatch(pesanpribadiEdit(pesanpribadi));
      history.push('/portal/pesanpribadi/' + data.messageId);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deletePesanpribadi = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deletePesanpribadi' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'pesan-pribadi/delete/' + id,
        formData,
        dispatch
      );
      const pesanpribadi = res.pesanpribadi;
      dispatch(pesanpribadiDelete(pesanpribadi));
      history.push('/portal/pesanpribadi');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
