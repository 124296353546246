import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { sessionLogout } from '../../login/redux/authApi';

function mapState(state) {
  return {
    auth: state.auth,
    loading: state.async.loading,
  };
}

const actions = {
  sessionLogout,
};

function NotFound(props) {
  const { sessionLogout, auth } = props;
  let history = useHistory();

  const handleSessionLogout = (elementName) => {
    sessionLogout(elementName, history);
  };

  return (
    <div className='column is-10-desktop is-offset-2-desktop is-9-tablet is-offset-3-tablet is-12-mobile'>
      <div className='p-1'>
        <div className='columns is-variable is-desktop'>
          <div
            className='column is-12'
            style={{
              height: 'calc(100vh - 112px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {' '}
            <div className='box' style={{ width: 400 }}>
              <div className='media'>
                <div className='media-content'>
                  <div className='content'>
                    <div id='fadein2' className='has-text-centered mt-3 mb-3'>
                      <i className='fas fa-exclamation-triangle icon is-size-1 has-text-danger' />
                    </div>
                    <div className='is-size-6 has-text-centered is-italic mt-1'>
                      Halaman tidak ditemukan!
                    </div>
                    {!auth.isAuth && (
                      <div className='has-text-link is-size-5 has-text-centered is-italic hand-pointer mt-3'>
                        <div
                          className='button is-link is-small is-rounded is-outlined'
                          onClick={() => handleSessionLogout('logoutNav')}
                        >
                          <i className='fas fa-sign-in-alt icon mr-1' /> Login
                        </div>
                        <br />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(NotFound));
