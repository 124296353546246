import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addPesanpribadi } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';

const actions = {
  closeModal,
  addPesanpribadi,
};

class PreviewModal extends Component {
  onSubmit = ({ auth, history, item }) => {
    const { addPesanpribadi, closeModal } = this.props;
    addPesanpribadi(auth, history, item);
    closeModal();
  };

  render() {
    const { data, closeModal } = this.props;
    const item = data.item;
    console.log(item);
    return (
      <div className='modal is-active'>
        <div className='modal-background' onClick={closeModal}></div>
        <div className='modal-card'>
          <header className='modal-card-head'>
            <div className='modal-card-title has-text-info has-text-weight-normal is-size-5'>
              <i className='mdi mdi-file-find icon is-size-5' /> Preview Pesan Pribadi
            </div>
            <button
              onClick={closeModal}
              className='delete'
              aria-label='close'
            ></button>
          </header>
          <section className='modal-card-body is-size-6'>
            <div className='table-container my-3 mx-1'>
              <table className='table is-bordered is-striped is-narrow is-hoverable is-fullwidth'>
                <thead>
                  <tr>
                    <th className='has-text-centered'>No</th>
                    <th className='has-text-centered'>NIS</th>
                    <th className='has-text-centered'>Nama Siswa</th>
                    <th className='has-text-centered'>Variabel</th>
                    <th className='has-text-centered'>Pesan Pribadi</th>
                  </tr>
                </thead>
                <tbody>
                  {item?.recipients?.map((i, key) => (
                    <tr key={key}>
                      <td className='has-text-centered' style={{ width: 50 }}>
                        {key + 1}
                      </td>
                      <td>{i?.nis}</td>
                      <td>{i?.name}</td>
                      <td>{i?.variable?.length > 0 && i?.variable?.map((v, key) => <span key={key} className='tag mr-1'>{v}</span>)}</td>
                      <td>
                        <p style={{ whiteSpace: 'pre-wrap' }}>{i?.messages}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
          <footer className='modal-card-foot'>
            <button
              onClick={() => this.onSubmit(data)}
              className='button is-info is-small is-rounded is-outlined'
            >
              <i className='is-size-6 mdi mdi-content-save icon' />
            </button>
            <button
              className='button custom-grey is-small is-rounded is-outlined'
              onClick={closeModal}
            >
              <i className='is-size-6 mdi mdi-arrow-left icon' />
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default connect(null, actions)(PreviewModal);
