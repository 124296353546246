import {
  PESANPRIBADIS_GET,
  PESANPRIBADI_ADD,
  PESANPRIBADI_GET,
  PESANPRIBADI_EDIT,
  PESANPRIBADI_REPORT,
  PESANPRIBADI_SEND,
  PESANPRIBADIPORTALUSERS_GET,
  PESANPRIBADI_UPLOADFILE,
  PESANPRIBADI_DELETE,
  PESANPRIBADI_IMPORT,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  items: [],
  uploadFiles: [],
  portalusers: [],
  reports: [],
};

const pesanpribadisGet = (state, payload) => {
  const pesanpribadis = payload.pesanpribadis;
  return {
    ...state,
    total: pesanpribadis.total,
    items: pesanpribadis.items,
  };
};

const pesanpribadiAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.pesanpribadi.id + ''),
      payload.pesanpribadi,
    ],
  };
};

const pesanpribadiGet = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.pesanpribadi.id + ''),
      payload.pesanpribadi,
    ],
  };
};

const pesanpribadiEdit = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.pesanpribadi.id + ''),
      payload.pesanpribadi,
    ],
  };
};

const pesanpribadiSend = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.pesanpribadi.id + ''),
      payload.pesanpribadi,
    ],
  };
};

const pesanpribadiReport = (state, payload) => {
  return {
    ...state,
    reports: [...payload.reports],
  };
};

const pesanpribadiPortalusersGet = (state, payload) => {
  const portalusers = payload.portalusers;
  let newPortalusers = [];
  for (const item of portalusers) {
    let description =
      item && item.description ? JSON.parse(item.description) : {};
    newPortalusers.push({
      ...item,
      key: item?.userId,
      nis: description?.nis,
      name: description?.name,
      activeClass: description?.activeClass,
      label: `${description?.name} - ${description?.activeClass}`,
      value: item?.userId,
    });
  }
  return {
    ...state,
    portalusers: newPortalusers,
  };
};

const pesanpribadiUploadFile = (state, payload) => {
  let uploadFile = payload.uploadFile;
  let uploadFiles = state.uploadFiles;
  let filteredUploadFiles = uploadFiles.filter(
    (i) => i.studentMessageId !== uploadFile.studentMessageId
  );
  let newUploadFiles = [...filteredUploadFiles, uploadFile];
  return {
    ...state,
    uploadFiles: newUploadFiles,
  };
};

const pesanpribadiDelete = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.pesanpribadi.id + ''),
    ],
  };
};

const pesanpribadiImport = (state, payload) => {
  const pesanpribadi = payload.pesanpribadi;
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== pesanpribadi.id + ''),
      pesanpribadi,
    ],
  };
};

export default createReducer(initialState, {
  [PESANPRIBADIS_GET]: pesanpribadisGet,
  [PESANPRIBADI_ADD]: pesanpribadiAdd,
  [PESANPRIBADI_GET]: pesanpribadiGet,
  [PESANPRIBADI_EDIT]: pesanpribadiEdit,
  [PESANPRIBADI_REPORT]: pesanpribadiReport,
  [PESANPRIBADI_SEND]: pesanpribadiSend,
  [PESANPRIBADIPORTALUSERS_GET]: pesanpribadiPortalusersGet,
  [PESANPRIBADI_UPLOADFILE]: pesanpribadiUploadFile,
  [PESANPRIBADI_DELETE]: pesanpribadiDelete,
  [PESANPRIBADI_IMPORT]: pesanpribadiImport,
});
