import { EMAILS_GET, EMAIL_GET, EMAIL_ADD, EMAIL_UPLOADFILE, EMAIL_SEND, EMAIL_DELETE } from './reduxConstant';

export const emailsGet = (emails) => {
  return {
    type: EMAILS_GET,
    payload: {
      emails,
    },
  };
};

export const emailGet = (email) => {
  return {
    type: EMAIL_GET,
    payload: {
      email,
    },
  };
};

export const emailAdd = (email) => {
  return {
    type: EMAIL_ADD,
    payload: {
      email,
    },
  };
};

export const emailUploadFile = (status) => {
  return {
    type: EMAIL_UPLOADFILE,
    payload: {
      status,
    },
  };
};

export const emailSend = (email) => {
  return {
    type: EMAIL_SEND,
    payload: {
      email,
    },
  };
};

export const emailDelete = (email) => {
  return {
    type: EMAIL_DELETE,
    payload: {
      email,
    },
  };
};
