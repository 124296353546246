export const WHATSAPPS_GET = 'WHATSAPPS_GET';
export const WHATSAPP_GET = 'WHATSAPP_GET';
export const WHATSAPPPORTALUSERS_GET = 'WHATSAPPPORTALUSERS_GET';
export const WHATSAPP_ADD = 'WHATSAPP_ADD';
export const WHATSAPP_EDIT = 'WHATSAPP_EDIT';
export const WHATSAPP_SEND = 'WHATSAPP_SEND';
export const WHATSAPP_REPORT = 'WHATSAPP_REPORT';
export const WHATSAPP_UPLOADFILE = 'WHATSAPP_UPLOADFILE';
export const WHATSAPP_STATUS = 'WHATSAPP_STATUS';
export const WHATSAPP_INFO = 'WHATSAPP_INFO';
export const WHATSAPP_DELETE = 'WHATSAPP_DELETE';
export const WHATSAPP_IMPORT = 'WHATSAPP_IMPORT';