import {
  EMAILS_GET,
  EMAIL_ADD,
  EMAIL_SEND,
  EMAIL_DELETE,
  EMAIL_UPLOADFILE,
  EMAIL_GET,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  uploadFiles: [],
  items: [],
};

const emailsGet = (state, payload) => {
  const emails = payload.emails;
  return {
    total: emails?.total ?? state.total,
    items: [...emails?.items] ?? state.items,
    search: { ...emails.search } ?? state.search,
  };
};

const emailGet = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.email.id + ''),
      payload.email,
    ],
  };
};

const emailAdd = (state, payload) => {
  return {
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.email.id + ''),
      payload.email,
    ],
    search: { ...state.search },
  };
};

const emailUploadFile = (state, payload) => {
  let status = payload.status;
  return {
    ...state,
    uploadStatus: status,
  };
};

const emailSend = (state, payload) => {
  return {
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.email.id + ''),
      payload.email,
    ],
    search: { ...state.search },
  };
};

const emailDelete = (state, payload) => {
  return {
    total: parseInt(state.total) + 1,
    items: [...state.items.filter((i) => i.id + '' !== payload.email.id + '')],
    search: { ...state.search },
  };
};

export default createReducer(initialState, {
  [EMAILS_GET]: emailsGet,
  [EMAIL_GET]: emailGet,
  [EMAIL_ADD]: emailAdd,
  [EMAIL_UPLOADFILE]: emailUploadFile,
  [EMAIL_SEND]: emailSend,
  [EMAIL_DELETE]: emailDelete,
});
