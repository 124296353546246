import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { addEmail, sendEmail, uploadFileEmail } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import TextInput from '../../../app/common/form/TextInput';
import {
  composeValidators,
  combineValidators,
  isRequired,
  createValidator,
} from 'revalidate';
import EditorQuill from '../../../app/common/form/EditorQuill';
import { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';
import { nanoid } from 'nanoid';
import { convertSize } from '../../../app/common/helpers/othersHelpers';

function mapState(state, ownProps) {
  const id = ownProps.data.id;
  let email = {};
  let uploadStatus;
  if (
    state &&
    state.emails &&
    state.emails &&
    state.emails.items &&
    state.emails.items.length > 0
  ) {
    email = state.emails.items.find((email) => email.id === id);
    uploadStatus = state.materis.uploadStatus;
  }
  return {
    loading: state.async.loading,
    email: email,
    uploadStatus: uploadStatus,
  };
}

const actions = {
  closeModal,
  addEmail,
  sendEmail,
  uploadFileEmail,
};

Quill.register('modules/imageUploader', ImageUploader);

const isValidEmail = createValidator(
  (message) => (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message;
    }
  },
  'Invalid email address'
);

const validate = combineValidators({
  email: composeValidators(
    isRequired({ message: 'Email harus diisi' }),
    isValidEmail
  )(),
  title: composeValidators(isRequired({ message: 'Judul harus diisi' }))(),
  description: composeValidators(
    isRequired({ message: 'Deskripsi harus diisi' })
  )(),
});

let onChangeRecipients;
let onChangeTitle;
let onChangeDescription;
const handleOnFormChange = (newValues, dispatch, props, previousValues) => {
  const {
    recipients: newRecipients,
    title: newTitle,
    description: newDescription,
  } = newValues;
  onChangeRecipients = newRecipients;
  onChangeTitle = newTitle;
  onChangeDescription = newDescription;
};

function DraftEmail(props) {
  const {
    loading,
    data,
    email,
    uploadStatus,
    closeModal,
    addEmail,
    sendEmail,
    uploadFileEmail,
  } = props;
  const auth = data.auth;
  const history = data.history;
  const id = data.id;

  useEffect(() => {
    addEmail(auth, history, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <div className='modal-card-title has-text-info has-text-weight-normal'>
            <i className='is-size-4 mdi mdi-pencil-box icon' /> Draft Email
          </div>
          <button
            onClick={closeModal}
            className='delete'
            aria-label='close'
          ></button>
        </header>
        <FormDraftEmail
          auth={auth}
          history={history}
          loading={loading}
          id={id}
          initialValues={email}
          uploadStatus={uploadStatus}
          closeModal={closeModal}
          sendEmail={sendEmail}
          uploadFileEmail={uploadFileEmail}
        />
      </div>
    </div>
  );
}

export default connect(mapState, actions)(DraftEmail);

let FormDraftEmail = (props) => {
  const {
    auth,
    history,
    loading,
    id,
    initialValues,
    uploadStatus,
    closeModal,
    handleSubmit,
    sendEmail,
    uploadFileEmail,
  } = props;
  let attachments =
    initialValues &&
    initialValues.attachments &&
    JSON.parse(initialValues.attachments);
  const attachedDiv = useRef(1);
  const [state, setState] = useState({
    id: id,
    recipients: '',
    title: '',
    description: '',
    attachments: [],
    fileList: [],
  });

  const modules = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
        { script: 'sub' },
        { script: 'super' },
      ],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
    ],
  };

  useEffect(() => {
    setState({
      id: id,
      title: initialValues?.title ?? '',
      description: initialValues?.description ?? '',
      recipients: initialValues?.recipients ?? '',
      attachments: attachments ?? [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadFile = async (fileItem) => {
    let title = onChangeTitle;
    let description = onChangeDescription;
    let recipients = onChangeRecipients;
    let data = {
      id,
      title,
      description,
      attachments: fileItem.attachments,
      recipients,
      file: fileItem.file,
      fileId: fileItem.fileId,
    };
    console.log(data);
    await uploadFileEmail(auth, history, data);
    attachedDiv.current++;
  };

  const handleUpload = (e, input) => {
    e.preventDefault();
    let files = e.target.files;
    let fileList = [];
    let newAttachments = state?.attachments ?? [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let fileId = nanoid();
      let attachment = {
        fileId: fileId,
        file: file,
        filename: file.name,
        filesize: file.size,
        filetype: file.type,
        filelink:
          `files/email/${auth.userId}/${id}/${Date.now()}_` +
          file.name.split(',').join('').split(' ').join('-'),
      };
      newAttachments.push(attachment);
      fileList.push({
        fileId: fileId,
        file: file,
        attachments: newAttachments,
      });
    }
    fileList.forEach((fileItem) => {
      try {
        uploadFile(fileItem);
      } catch (err) {
        console.log('Error:', err);
      }
    });
  };

  const onFormSubmit = async () => {
    try {
    } catch (err) {
      console.log(err);
    }
    let title = onChangeTitle;
    let description = onChangeDescription;
    let recipients = onChangeRecipients;
    let attachments = state.attachments;
    const data = {
      id: id,
      title,
      description,
      recipients,
      attachments,
    };
    sendEmail(auth, history, data);
  };

  const renderFileInput = ({
    input,
    type,
    meta,
    label,
    placeholder,
    fileNames,
  }) => {
    return (
      <div className='field'>
        <div className='label'>{label}</div>
        <div className='control'>
          <div className='file has-name is-fullwidth'>
            <label className='file-label'>
              <input
                multiple
                name={input.name}
                accept='image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.ppt,.pptx,.xls,.xlsx,.zip'
                className='file-input'
                type={type}
                onChange={(event) => handleUpload(event, input)}
              />
              <span className='file-cta'>
                <span className='file-icon'>
                  <i className='is-size-6 mdi mdi-upload'></i>
                </span>
                <span className='file-label'>{placeholder}</span>
              </span>
              <span className='file-name'>{fileNames}</span>
            </label>
          </div>
        </div>
        {meta && meta.invalid && meta.error && <div>{meta.error}</div>}
      </div>
    );
  };

  return (
    <form
      className='overflow-auto'
      onSubmit={handleSubmit(onFormSubmit)}
      autoComplete='off'
    >
      <section className='modal-card-body is-size-6'>
        <div className='content ml-3'>
          <Field
            name='recipients'
            type='text'
            component={TextInput}
            placeholder='Email'
            label='Email'
          />
          <Field
            name='title'
            type='text'
            component={TextInput}
            placeholder='Judul'
            label='Judul'
          />
          <Field
            name='description'
            type='text'
            component={EditorQuill}
            modules={modules}
            label='Deskripsi'
            placeholder='Deskripsi disini...'
          />
          <Field
            name='attachment'
            type='file'
            component={renderFileInput}
            placeholder='File'
            label='File Attachement'
          />
          <div
            className='column is-full-tablet is-three-quarters-desktop'
            ref={attachedDiv}
            style={{ marginTop: -10, marginLeft: -10 }}
          >
            {uploadStatus === 'start' && (
              <progress
                className='progress is-small is-info'
                max='100'
                style={{ marginBottom: 8 }}
              >
                0%
              </progress>
            )}
            {attachments &&
              attachments.map((item, index) => (
                <div
                  key={index}
                  className='has-text-link has-background-grey-lighter px-2 py-1 mb-1 flex justify-between align-center'
                >
                  <span
                    className='hand-pointer'
                    onClick={() => this.onClickAttachment(item)}
                  >
                    {item.filename} (
                    {item?.filesize && convertSize(item.filesize)})
                  </span>
                  <button
                    onClick={(e) => this.handleClickFileDelete(e, item)}
                    className='delete'
                  ></button>
                </div>
              ))}
          </div>
        </div>
      </section>
      <footer className='modal-card-foot'>
        <button
          type='submit'
          disabled={loading}
          className={
            loading
              ? 'button is-info is-small is-rounded is-outlined is-loading'
              : 'button is-info is-small is-rounded is-outlined'
          }
        >
          <i className='is-size-6 mdi mdi-content-save icon' />
        </button>
        <button
          className='button custom-grey is-small is-rounded is-outlined'
          onClick={closeModal}
        >
          <i className='is-size-6 mdi mdi-arrow-left icon' />
        </button>
      </footer>
    </form>
  );
};

FormDraftEmail = reduxForm({
  form: 'draftEmail',
  onChange: handleOnFormChange,
  validate,
  enableReinitialize: true,
})(FormDraftEmail);
