import {
  WHATSAPPS_GET,
  WHATSAPP_ADD,
  WHATSAPP_GET,
  WHATSAPPPORTALUSERS_GET,
  WHATSAPP_EDIT,
  WHATSAPP_REPORT,
  WHATSAPP_SEND,
  WHATSAPP_UPLOADFILE,
  WHATSAPP_STATUS,
  WHATSAPP_INFO,
  WHATSAPP_DELETE,
  WHATSAPP_IMPORT,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  items: [],
  uploadFiles: [],
  portalusers: [],
  reports: [],
};

const whatsappsGet = (state, payload) => {
  const whatsapps = payload.whatsapps;
  return {
    ...state,
    total: whatsapps.total,
    items: whatsapps.items,
  };
};

const whatsappPortalusersGet = (state, payload) => {
  const portalusers = payload.portalusers;
  let newPortalusers = [];
  for (const item of portalusers) {
    let description =
      item && item.description ? JSON.parse(item.description) : {};
    newPortalusers.push({
      ...item,
      key: item?.userId,
      nis: description?.nis,
      name: description?.name,
      activeClass: description?.activeClass,
      label: `${description?.name} - ${description?.activeClass}`,
      value: item?.userId,
    });
  }
  return {
    ...state,
    portalusers: newPortalusers,
  };
};

const whatsappAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.whatsapp.id + ''),
      payload.whatsapp,
    ],
  };
};

const whatsappGet = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.whatsapp.id + ''),
      payload.whatsapp,
    ],
  };
};

const whatsappEdit = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.whatsapp.id + ''),
      payload.whatsapp,
    ],
  };
};

const whatsappSend = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.whatsapp.id + ''),
      payload.whatsapp,
    ],
  };
};

const whatsappReport = (state, payload) => {
  let recipientReports = payload.whatsapp && payload.whatsapp.recipients;
  let reportsJSON = recipientReports && JSON.parse(recipientReports);
  if (reportsJSON) {
    return {
      ...state,
      reports: [...reportsJSON],
    };
  } else {
    return state;
  }
};

const whatsappUploadFile = (state, payload) => {
  let uploadFile = payload.uploadFile;
  let uploadFiles = state.uploadFiles;
  let filteredUploadFiles = uploadFiles.filter(
    (i) => i.id !== uploadFile.id
  );
  let newUploadFiles = [...filteredUploadFiles, uploadFile];
  return {
    ...state,
    uploadFiles: newUploadFiles,
  };
};

const whatsappDelete = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.whatsapp.id + ''),
    ],
  };
};

const whatsappStatus = (state, payload) => {
  let status = payload.status;
  return {
    ...state,
    status: status.status,
    info: status.info,
  };
};

const whatsappInfo = (state, payload) => {
  let info = payload.info;
  return {
    ...state,
    info: info,
  };
};

const whatsappImport = (state, payload) => {
  const whatsapp = payload.whatsapp;
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== whatsapp.id + ''),
      whatsapp,
    ],
  };
};

export default createReducer(initialState, {
  [WHATSAPPS_GET]: whatsappsGet,
  [WHATSAPP_ADD]: whatsappAdd,
  [WHATSAPP_GET]: whatsappGet,
  [WHATSAPPPORTALUSERS_GET]: whatsappPortalusersGet,
  [WHATSAPP_EDIT]: whatsappEdit,
  [WHATSAPP_REPORT]: whatsappReport,
  [WHATSAPP_SEND]: whatsappSend,
  [WHATSAPP_UPLOADFILE]: whatsappUploadFile,
  [WHATSAPP_STATUS]: whatsappStatus,
  [WHATSAPP_INFO]: whatsappInfo,
  [WHATSAPP_DELETE]: whatsappDelete,
  [WHATSAPP_IMPORT]: whatsappImport,
});
