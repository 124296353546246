import {
  WHATSAPPS_GET,
  WHATSAPP_GET,
  WHATSAPPPORTALUSERS_GET,
  WHATSAPP_ADD,
  WHATSAPP_EDIT,
  WHATSAPP_SEND,
  WHATSAPP_REPORT,
  WHATSAPP_STATUS,
  WHATSAPP_INFO,
  WHATSAPP_DELETE,
  WHATSAPP_IMPORT,
  WHATSAPP_UPLOADFILE,
} from './reduxConstant';

export const whatsappsGet = (whatsapps) => {
  return {
    type: WHATSAPPS_GET,
    payload: {
      whatsapps,
    },
  };
};

export const whatsappPortalusersGet = (portalusers) => {
  return {
    type: WHATSAPPPORTALUSERS_GET,
    payload: {
      portalusers,
    },
  };
};

export const whatsappAdd = (whatsapp) => {
  return {
    type: WHATSAPP_ADD,
    payload: {
      whatsapp,
    },
  };
};

export const whatsappGet = (whatsapp) => {
  return {
    type: WHATSAPP_GET,
    payload: {
      whatsapp,
    },
  };
};

export const whatsappEdit = (whatsapp) => {
  return {
    type: WHATSAPP_EDIT,
    payload: {
      whatsapp,
    },
  };
};

export const whatsappSend = (whatsapp) => {
  return {
    type: WHATSAPP_SEND,
    payload: {
      whatsapp,
    },
  };
};

export const whatsappReport = (whatsapp) => {
  return {
    type: WHATSAPP_REPORT,
    payload: {
      whatsapp,
    },
  };
};

export const whatsappUploadFile = (uploadFile) => {
  return {
    type: WHATSAPP_UPLOADFILE,
    payload: {
      uploadFile,
    },
  };
};

export const whatsappStatus = (status) => {
  return {
    type: WHATSAPP_STATUS,
    payload: {
      status,
    },
  };
};

export const whatsappInfo = (info) => {
  return {
    type: WHATSAPP_INFO,
    payload: {
      info,
    },
  };
};

export const whatsappDelete = (whatsapp) => {
  return {
    type: WHATSAPP_DELETE,
    payload: {
      whatsapp,
    },
  };
};

export const whatsappImport = (whatsapp) => {
  return {
    type: WHATSAPP_IMPORT,
    payload: {
      whatsapp,
    },
  };
};
